@import url('https://fonts.googleapis.com/css2?family=Martel+Sans:wght@200;300;400;600;700;800;900&display=swap');


@font-face {
  font-family: "Lufga";
  src: url("./assets/fonts/lufga/LufgaSemiBold.ttf") format("truetype");
  src: url("./assets/fonts/lufga/LufgaMedium.ttf") format("truetype");
  src: url("./assets/fonts/lufga/LufgaSemiBold.woff") format("woff");
  src: url("./assets/fonts/lufga/LufgaMedium.woff") format("woff");
  src: url("./assets/fonts/lufga/LufgaSemiBold.woff2") format("woff2");
  src: url("./assets/fonts/lufga/LufgaMedium.woff2") format("woff2");
  src: url("./assets/fonts/lufga/LufgaRegular.ttf") format("truetype");
}


@font-face {
  font-family: "Martel Sans";
  src: url("./assets/fonts/Martel-sans/Martel Sans Regular.ttf") format("truetype");
}

.App {
  margin: 0 auto;
  padding: 0;
  scroll-behavior: smooth;
  background: #ffffff;
  overflow: hidden;
}

* {
  scroll-behavior: smooth;
}

.homepage-container {
  max-width: 1920px;
  width: 100%;
  margin: 0 auto;
  overflow-x: hidden;
}

.custom-contact-input-wrapper {
  background-color: white;
}

.custom-contact-input {
  font-family: "Poppins", sans-serif;
  width: 100%;
  border: none !important;
  font-size: 14px;
  color: #000;
  background: transparent !important;
}

.custom-contact-input:hover {
  border: none !important;
  box-shadow: none !important;
  outline: none;
}

.custom-contact-input:focus {
  border: none !important;
  box-shadow: none !important;
  outline: none;
}

.custom-contact-input:active {
  border: none;
  box-shadow: none;
  outline: none;
}

.custom-contact-input::placeholder {
  color: #222222;
  font-size: 14px;
  font-style: normal;
  line-height: normal;
}

.custom-btn {
  position: relative;
  transition: all 1.5s;
  overflow: hidden;
  transition: all 0.3s linear;
  background: #03045e;
  color: #fff;
}

.custom-btn .custom-btn-inner {
  position: relative;
  z-index: 99;
}

.custom-btn:after {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  width: 0;
  height: 100%;
  background: #007ea7;
  transition: all 0.6s;
}

.custom-btn:hover {
  color: #fff;
}

.custom-btn:hover:after {
  width: 100%;
  background: #007ea7;
  color: white;
}

.custom-btn2 {
  overflow: hidden;
  position: relative;
  text-decoration: none;
  transition: 0.7s ease;
  z-index: 1;
  background: #ffffff;
  color: #000;
  border: 1px solid #000000;
}

.custom-btn2:hover {
  border: 1px solid #1f999b;
  color: #fff;
}

.custom-btn2:before,
.custom-btn2:after {
  color: #fff;
  background: #1f999b;
  transition: 0.7s ease;
  content: "";
  position: absolute;
  z-index: -1;
  border: 1px solid #1f999b;
}

.middle-out:before {
  top: 50%;
  right: 50%;
  bottom: 50%;
  left: 50%;
}

.middle-out:hover:before {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

/* header */

header {
  width: 100%;
  height: 104px;
  z-index: 999;
  top: 0;
  position: fixed;
  display: flex;
  align-items: center;
  transition-duration: 0.5s;
  -webkit-transition-duration: 0.5s;
  -moz-transition-duration: 0.5s;
}

.shrink {
  height: 90px;
  background: #fff;
  z-index: 999;
}

.active-profile-sidebar {
  position: fixed;
  z-index: 9999;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: rgba(255, 255, 255, 1);
  transition: all 0.5s;
}

.not-active-profile-sidebar {
  position: fixed;
  z-index: 9999;
  top: 0;
  left: -100%;
  width: 100%;
  background: rgba(255, 255, 255, 1);
  transition: all 0.5s;
}

.profile-sidebar-inner {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
}

.inputs-z {
  position: relative;
  z-index: 99;
}

/* hero */

.bg-hero {
  background: #03045e;
}

.bg-hero1 {
  background: linear-gradient(180deg, rgba(0, 180, 216, 0.15) 0%, rgba(0, 0, 0, 0.24) 64.06%);
}

.bg-hero-form {
  background: linear-gradient(0deg, rgba(3, 4, 94, 0.7), rgba(3, 4, 94, 0.7));
}

.bg-hero-form1 {
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.28) 23.96%, rgba(0, 126, 167, 0.4) 93.23%);
}

.image-animation {
  transform: scale(1);
  transition: transform 0.5s ease-in-out;
}

.image-animation:hover {
  transition: transform 0.5s ease-in-out;
  transform: scale(1.2);
}

/* solutions */

.bg-solutions {
  background: url("./assets/images/bg-solutions.png");
  background-position: 100%;
  background-size: 100% 95%;
  background-repeat: no-repeat;
  height: 50%;
}

/* working */

.bg-work1 {
  background: url("./assets/images/bg-work1.png");
  background-position: top;
  background-size: cover;
  background-repeat: no-repeat;
}

.bg-work2 {
  background: url("./assets/images/bg-work2.png");
  background-position: top;
  background-size: cover;
  background-repeat: no-repeat;
}

.bg-work3 {
  background: url("./assets/images/bg-work3.png");
  background-position: top;
  background-size: cover;
  background-repeat: no-repeat;
}

/* faq */

.faq-bg {
  background: url("./assets/images/faq-bg.png");
  background-position: top;
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
}

/* company hero */

.bg-company-hero {
  background: linear-gradient(180deg, rgba(0, 180, 216, 0.25) 0%, rgba(255, 255, 255, 0.3) 64.06%);
}

.bg-company-solution1 {
  background: linear-gradient(180deg, rgba(217, 244, 249, 0) 8.5%, rgba(217, 244, 249, 0.4) 92.5%);
}

/* bond */

.bond-main-border {
  border: 0.7px solid rgba(176, 173, 173, 0.69);
}

.bg-a-working {
  background: linear-gradient(360deg,
      rgba(0, 180, 216, 0.25) 0%,
      rgba(255, 255, 255, 0.282) 49.5%,
      rgba(255, 255, 255, 0.282) 64.06%);
}

.bg-a-hero {
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url("./assets/images/a-hero.png");
  background-position: top;
  background-size: cover;
  background-repeat: no-repeat;
}

.contact-border {
  border-top: 0.5px solid rgba(170, 170, 170, 0.59);
}

.market-select-wrapper {
  :where(.css-dev-only-do-not-override-amq5gd).ant-select:not(.ant-select-customize-input) .ant-select-selector {
    display: flex;
    column-gap: 10px;
  }

  :where(.css-dev-only-do-not-override-amq5gd).ant-select:not(.ant-select-customize-input) .ant-select-selector {
    background-color: transparent !important;
    border: none !important;
  }

  :where(.css-dev-only-do-not-override-amq5gd).ant-select-single .ant-select-selector .ant-select-selection-item,
  :where(.css-dev-only-do-not-override-amq5gd).ant-select-single .ant-select-selector .ant-select-selection-placeholder {
    color: #03045e;
    font-size: 19px;
    font-weight: bold;
    font-family: "Martel Sans";
  }

  :where(.css-dev-only-do-not-override-amq5gd).ant-select .ant-select-arrow {
    color: #03045e;
    font-size: 18px;
  }
}

.duration-select-wrapper {
  :where(.css-dev-only-do-not-override-amq5gd).ant-select:not(.ant-select-customize-input) .ant-select-selector {
    display: flex;
    column-gap: 5px;
  }

  :where(.css-dev-only-do-not-override-amq5gd).ant-select:not(.ant-select-customize-input) .ant-select-selector {
    background-color: transparent !important;
    border: none !important;
  }

  :where(.css-dev-only-do-not-override-amq5gd).ant-select-single .ant-select-selector .ant-select-selection-item,
  :where(.css-dev-only-do-not-override-amq5gd).ant-select-single .ant-select-selector .ant-select-selection-placeholder {
    color: #fff;
    font-size: 16px;
    font-weight: bold;
    font-family: "Martel Sans";
  }

  :where(.css-dev-only-do-not-override-amq5gd).ant-select .ant-select-arrow {
    color: #fff;
    font-size: 16px;
  }
}

/* media queries */

@media screen and (min-width: 1920px) {
  .bg-hero-form-padding {
    padding-left: 100px;
    padding-right: 100px;
  }
}

@media screen and (max-width: 1919px) and (min-width: 1801px) {
  .bg-hero-form-padding {
    padding-left: 100px;
    padding-right: 100px;
  }

  .a-application-title {
    width: 60%;
  }

  .a-application-image {
    width: 90%;
  }

  .a-main-margin {
    margin-top: 50px;
  }

  .b-main-title {
    width: 55%;
  }
}

@media screen and (max-width: 1800px) and (min-width: 1701px) {
  .h-hero-title {
    width: 100%;
  }

  .bg-hero-form-padding {
    padding-left: 80px;
    padding-right: 80px;
  }

  .working-responsiove-padding {
    padding-left: 100px;
    padding-right: 100px;
  }

  .working-responsive-title {
    font-size: 39.81px;
  }

  .company-hero-padding-responsive {
    padding-left: 100px;
  }

  .c-solution1-padding {
    padding-left: 100px;
  }

  .c-solution1-width {
    width: 85%;
  }

  .a-application-title {
    width: 70%;
  }

  .a-application-image {
    width: 90%;
  }

  .a-main-margin {
    margin-top: 100px;
  }

  .b-main-title {
    width: 60%;
  }

  .market-data-gap {
    column-gap: 30px;
  }

  .market-wrapper-gap {
    column-gap: 70px;
  }

  .market-wrapper-padding {
    padding-left: 70px;
    padding-right: 70px;
  }
}

@media screen and (max-width: 1700px) and (min-width: 1536px) {
  .h-hero-title {
    width: 100%;
  }

  .bg-hero-form-padding {
    padding-left: 70px;
    padding-right: 70px;
  }

  .h-solution-title {
    width: 100%;
  }

  .working-responsiove-padding {
    padding-left: 90px;
    padding-right: 90px;
  }

  .working-responsive-title {
    font-size: 34px;
  }

  .problem-responsive-gap {
    column-gap: 20px;
  }

  .problem-responsive-padding {
    padding-left: 20px;
    padding-right: 20px;
  }

  .problem-responsive-title {
    font-size: 24px;
  }

  .problem-responsive-description {
    font-size: 17px;
  }

  .company-hero-padding-responsive {
    padding-left: 80px;
  }

  .c-solution1-padding {
    padding-left: 70px;
  }

  .c-solution1-width {
    width: 90%;
  }

  .a-application-title {
    width: 70%;
  }

  .a-application-image {
    width: 100%;
  }

  .a-main-margin {
    margin-top: 200px;
  }

  .b-main-title {
    width: 70%;
  }

  .market-data-gap {
    column-gap: 30px;
  }

  .market-wrapper-gap {
    column-gap: 50px;
  }

  .market-wrapper-padding {
    padding-left: 50px;
    padding-right: 50px;
  }
}

@media screen and (max-width: 1535px) and (min-width: 1280px) {
  .market-select-wrapper {
    :where(.css-dev-only-do-not-override-amq5gd).ant-select:not(.ant-select-customize-input) .ant-select-selector {
      display: flex;
      column-gap: 5px;
    }

    :where(.css-dev-only-do-not-override-amq5gd).ant-select:not(.ant-select-customize-input) .ant-select-selector {
      background-color: transparent !important;
      border: none !important;
    }

    :where(.css-dev-only-do-not-override-amq5gd).ant-select-single .ant-select-selector .ant-select-selection-item,
    :where(.css-dev-only-do-not-override-amq5gd).ant-select-single .ant-select-selector .ant-select-selection-placeholder {
      font-size: 16px;
    }

    :where(.css-dev-only-do-not-override-amq5gd).ant-select .ant-select-arrow {
      font-size: 15px;
      margin-top: -7px;
    }
  }
}

@media screen and (max-width: 1279px) and (min-width: 1024px) {
  .custom-contact-input {
    font-size: 15px;
  }

  .custom-contact-input::placeholder {
    font-size: 15px;
  }
}

@media screen and (max-width: 1023px) and (min-width: 900px) {
  .custom-contact-input {
    font-size: 15px;
  }

  .custom-contact-input::placeholder {
    font-size: 15px;
  }

  header {
    height: 100px;
  }
}

@media screen and (max-width: 899px) and (min-width: 768px) {
  .custom-contact-input {
    font-size: 15px;
  }

  .custom-contact-input::placeholder {
    font-size: 15px;
  }

  header {
    height: 100px;
  }

  .market-select-wrapper {
    :where(.css-dev-only-do-not-override-amq5gd).ant-select:not(.ant-select-customize-input) .ant-select-selector {
      display: flex;
      column-gap: 5px;
    }

    :where(.css-dev-only-do-not-override-amq5gd).ant-select:not(.ant-select-customize-input) .ant-select-selector {
      background-color: transparent !important;
      border: none !important;
    }

    :where(.css-dev-only-do-not-override-amq5gd).ant-select-single .ant-select-selector .ant-select-selection-item,
    :where(.css-dev-only-do-not-override-amq5gd).ant-select-single .ant-select-selector .ant-select-selection-placeholder {
      font-size: 14px;
    }

    :where(.css-dev-only-do-not-override-amq5gd).ant-select .ant-select-arrow {
      font-size: 14px;
      margin-top: -7px;
    }
  }

  .duration-select-wrapper {
    :where(.css-dev-only-do-not-override-amq5gd).ant-select:not(.ant-select-customize-input) .ant-select-selector {
      column-gap: 5px;
    }

    :where(.css-dev-only-do-not-override-amq5gd).ant-select:not(.ant-select-customize-input) .ant-select-selector {
      background-color: transparent !important;
      border: none !important;
    }

    :where(.css-dev-only-do-not-override-amq5gd).ant-select-single .ant-select-selector .ant-select-selection-item,
    :where(.css-dev-only-do-not-override-amq5gd).ant-select-single .ant-select-selector .ant-select-selection-placeholder {
      font-size: 14px;
    }

    :where(.css-dev-only-do-not-override-amq5gd).ant-select .ant-select-arrow {
      font-size: 14px;
    }
  }
}

@media screen and (max-width: 767px) and (min-width: 426px) {
  .custom-contact-input {
    font-size: 14px;
  }

  .custom-contact-input::placeholder {
    font-size: 14px;
  }

  header {
    height: 90px;
  }

  .shrink {
    height: 80px;
  }

  .market-select-wrapper {
    :where(.css-dev-only-do-not-override-amq5gd).ant-select:not(.ant-select-customize-input) .ant-select-selector {
      display: flex;
      column-gap: 5px;
    }

    :where(.css-dev-only-do-not-override-amq5gd).ant-select:not(.ant-select-customize-input) .ant-select-selector {
      background-color: transparent !important;
      border: none !important;
    }

    :where(.css-dev-only-do-not-override-amq5gd).ant-select-single .ant-select-selector .ant-select-selection-item,
    :where(.css-dev-only-do-not-override-amq5gd).ant-select-single .ant-select-selector .ant-select-selection-placeholder {
      font-size: 14px;
    }

    :where(.css-dev-only-do-not-override-amq5gd).ant-select .ant-select-arrow {
      font-size: 14px;
      margin-top: -7px;
    }
  }

  .duration-select-wrapper {
    :where(.css-dev-only-do-not-override-amq5gd).ant-select:not(.ant-select-customize-input) .ant-select-selector {
      column-gap: 5px;
    }

    :where(.css-dev-only-do-not-override-amq5gd).ant-select:not(.ant-select-customize-input) .ant-select-selector {
      background-color: transparent !important;
      border: none !important;
    }

    :where(.css-dev-only-do-not-override-amq5gd).ant-select-single .ant-select-selector .ant-select-selection-item,
    :where(.css-dev-only-do-not-override-amq5gd).ant-select-single .ant-select-selector .ant-select-selection-placeholder {
      font-size: 14px;
    }

    :where(.css-dev-only-do-not-override-amq5gd).ant-select .ant-select-arrow {
      font-size: 14px;
    }
  }
}

@media screen and (max-width: 425px) {
  .custom-contact-input {
    font-size: 14px;
  }

  .custom-contact-input::placeholder {
    font-size: 14px;
  }

  header {
    height: 90px;
  }

  .shrink {
    height: 80px;
  }

  .market-select-wrapper {
    :where(.css-dev-only-do-not-override-amq5gd).ant-select:not(.ant-select-customize-input) .ant-select-selector {
      display: flex;
      column-gap: 5px;
    }

    :where(.css-dev-only-do-not-override-amq5gd).ant-select:not(.ant-select-customize-input) .ant-select-selector {
      background-color: transparent !important;
      border: none !important;
    }

    :where(.css-dev-only-do-not-override-amq5gd).ant-select-single .ant-select-selector .ant-select-selection-item,
    :where(.css-dev-only-do-not-override-amq5gd).ant-select-single .ant-select-selector .ant-select-selection-placeholder {
      font-size: 14px;
    }

    :where(.css-dev-only-do-not-override-amq5gd).ant-select .ant-select-arrow {
      font-size: 14px;
      margin-top: -7px;
    }
  }

  .duration-select-wrapper {
    :where(.css-dev-only-do-not-override-amq5gd).ant-select:not(.ant-select-customize-input) .ant-select-selector {
      column-gap: 5px;
    }

    :where(.css-dev-only-do-not-override-amq5gd).ant-select:not(.ant-select-customize-input) .ant-select-selector {
      background-color: transparent !important;
      border: none !important;
    }

    :where(.css-dev-only-do-not-override-amq5gd).ant-select-single .ant-select-selector .ant-select-selection-item,
    :where(.css-dev-only-do-not-override-amq5gd).ant-select-single .ant-select-selector .ant-select-selection-placeholder {
      font-size: 14px;
    }

    :where(.css-dev-only-do-not-override-amq5gd).ant-select .ant-select-arrow {
      font-size: 14px;
    }
  }
}

.ant-collapse-header {
  font-size: 16px !important;
  line-height: 29.18px !important;
  font-weight: 700 !important;
}